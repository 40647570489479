<template>
  <div>
    <div class="custom-search mb-2">
      <b-row>
        <b-col md="4">
          <label for="lead-type"> Выберите пользователя: </label>
          <b-form-select
            id="lead-type"
            :options="usersList"
            v-model="filters.user"
            @change="filterByUser"
          >
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <integrations-table
      :integrations="getIntegrationsList"
      @setStatus="setStatus"
      @editIntegration="editIntegration"
    />
  </div>
</template>

<script>
import { BFormSelect, BRow, BCol } from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";

import IntegrationsTable from "./IntegrationsTable.vue";

export default {
  components: {
    IntegrationsTable,
    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    ...mapGetters("admin", ["getUsers", "getIntegrations"]),
    getIntegrationsList() {
      let integrations = [];
      for (const [key, integration] of Object.entries(
        this.getIntegrations.answer
      )) {
        let user = "";
        if (this.getUsers.answer[integration.userId])
          user = this.getUsers.answer[integration.userId].email;
        integrations.push({ ...integration, user, id: key });
      }
      if (this.$route.query.user) {
        integrations = integrations.filter(
          (integration) => integration.userId === this.$route.query.user
        );
      }
      return integrations;
    },
    usersList() {
      const users = [{ value: null, text: "Все" }];
      for (const [key, user] of Object.entries(this.getUsers.answer)) {
        users.push({ value: key, text: user.email });
      }
      return users;
    },
  },
  methods: {
    ...mapActions("admin", ["requestUsers", "requestIntegrations"]),
    ...mapActions("integrations", ["setIntegrationStatus"]),
    filterByUser() {
      this.$router.push({ query: { user: this.filters.user } });
    },
    setStatus(integration) {
      const key = Object.keys(this.getIntegrations.answer).find(
        (key) =>
          this.getIntegrations.answer[key].displayId === integration.displayId
      );
      this.setIntegrationStatus({
        id: key,
        status: integration.status,
        displayId: integration.displayId,
      });
    },
    editIntegration(integration) {
      const key = Object.keys(this.getIntegrations.answer).find(
        (key) =>
          this.getIntegrations.answer[key].displayId === integration.displayId
      );
      switch (integration.type) {
        case "Webhook": {
          this.$router.push({
            name: "integrations-webhook",
            params: { id: key },
          });
          break;
        }
        case "Email": {
          this.$router.push({
            name: "integrations-email",
            params: { id: key },
          });
          break;
        }
        case "googleAnalytics": {
          this.$router.push({
            name: "integrations-google",
            params: { id: key },
          });
          break;
        }
        case "yandexMetrika": {
          this.$router.push({
            name: "integrations-yandex",
            params: { id: key },
          });
          break;
        }
        case "facebookPixel": {
          this.$router.push({
            name: "integrations-facebook",
            params: { id: key },
          });
          break;
        }
      }
    },
  },
  created() {
    this.requestUsers();
    this.requestIntegrations();
    if (this.$route.query.user) {
      this.filters.user = this.$route.query.user;
    }
  },
};
</script>
