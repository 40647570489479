<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="integrations"
      :rtl="direction"
      :bordered="false"
      class="projects-table"
      styleClass="vgt-table"
    >
      <div slot="emptystate" class="text-center">
        Интеграций нет
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'status'" class="text-nowrap">
          <span class="text-nowrap">
            <b-form-checkbox
              v-model="props.row.status"
              name="check-button"
              @change="$emit('setStatus', props.row)"
              switch
            >
            </b-form-checkbox>
          </span>
        </span>
        <span v-else-if="props.column.field === 'id'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.displayId }}</span>
        </span>

        <span v-else-if="props.column.field === 'date'" class="text-nowrap">
          <span class="text-nowrap">{{
            new Date(props.row.date.seconds * 1000).toLocaleDateString() +
              " " +
              props.row.date.toDate().toLocaleTimeString("ru-RU")
          }}</span>
        </span>

        <span v-else-if="props.column.field === 'name'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <span v-else-if="props.column.field === 'type'" class="text-nowrap">
          <span class="text-nowrap">{{
            props.row.type === "Webhook" && !isMainInstance
              ? "1С: Фитнес клуб"
              : props.row.type
          }}</span>
        </span>

        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex justify-content-around">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50 cursor-pointer"
              size="18"
              @click="$emit('editIntegration', props.row)"
            />
          </span>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueGoodTable,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: ["integrations"],
  data() {
    return {
      dir: false,
      rows: [],
      columns: [
        {
          label: "Статус",
          field: "status",
        },
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Автор",
          field: "user",
        },
        {
          label: "Подключено",
          field: "date",
        },
        {
          label: "Название",
          field: "name",
        },
        {
          label: "Система",
          field: "type",
        },
        {
          label: "Действия",
          field: "action",
        },
      ],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    isMainInstance() {
      return !process.env.VUE_APP_ENV || process.env.VUE_APP_ENV !== "white";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.projects-table tr:hover {
  background-color: #f8f8f8;
}
</style>
